/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2024-03-13 09:06:34
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-05-16 00:15:35
 */
import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import * as ElIcons from "@element-plus/icons-vue";
import "nprogress/nprogress.css";
import "@/assets/app.css";
import router from "./router/router";
import http from "./http/http";
import config from "./plugins/in_config";
import power from "./plugins/power";
import field from "./plugins/field";
import * as Fields from "./views/components/field";
import * as CommonUI from "./views/components/common";

const app = createApp(App);
app.use(router);
app.use(http);
app.use(config);
app.use(power);
app.use(field);

app.use(ElementPlus, { locale: zhCn });

for (const [key, component] of Object.entries(ElIcons)) {
  app.component(key, component);
}

for (const [key, component] of Object.entries(Fields)) {
  app.component(key, component);
}
for (const [key, component] of Object.entries(CommonUI)) {
  app.component(key, component);
}



app.mount("#app");
