<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2024-07-16 19:08:52
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-07-16 19:12:01
-->
<template>
  <div>{{ text }}</div>
</template>

<script>
export default {
  data() {
    return {
      text: "",
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  watch: {
    value: {
      handler(val) {
        console.log(val);
        if (val) {
          
            this.text= val;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
 
</style>
