<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2024-05-09 20:06:22
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-05-30 12:59:19
-->
<template>
  <div class="album">
    <el-image
      :preview-src-list="srcList"
      v-for="(url, index) in srcList"
      :initial-index="index"
      :key="url"
      :src="url"
      style="width: 60px; height: 50px"
    ></el-image>
  </div>
</template>

<script>
export default {
  data() {
    return {
      srcList: [],
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  watch: {
    value: {
      handler() {
        this.srcList = [];
        if (this.value) {
          var list = JSON.parse(this.value);
          if (list.length) {
            this.srcList = list.map((x) => x.file_url);
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.album {
  display: flex;
  flex-wrap: wrap;
}
</style>
