/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2022-05-11 01:50:20
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-05-10 08:41:22
 */

import http from "./axios_init";

export default {
  /**
   * 获取 menu 的参数
   * @returns
   */
  get_enums() {
    return http.get("v1/common/enums");
  },

  /**
   * 获取系统的配置参数（完整）
   */
  get_settings() {
    return http.get("seller/v1/common/config");
  },

  /**
   * 简单公共部分
   */
  get_setting() {
    return http.get("v1/common/config");
  },

  /**
   * 省市县 tree格式
   * @returns
   */
  get_region_tree() {
    return http.get("v1/common/region/tree");
  },

  /**
   * 获取批次id
   * @returns
   */
  get_batch_id() {
    return http.get("v1/common/get_batchid");
  },

  /**
   * 数据权限数据
   * @returns
   */
  get_permission_data() {
    return http.get("seller/v1/common/data_prm");
  },
};
