<!--
 * @Description: 员工选择
 * @Author: 琢磨先生
 * @Date: 2024-05-06 09:30:41
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-06-02 14:22:07
-->
<template>
  <el-form-item
    :label="field.name"
    :class="`${field.is_required ? 'required' : ''}`"
  >
    <!-- <el-cascader
      :options="options"
      v-model="values"
      :props="{ multiple: field.is_multiple }"
      :style="field.style_percent ? `width:${field.style_percent}%` : ``"
      @change="onChange"
    ></el-cascader> -->
    <el-popover :width="800" trigger="click">
      <template #reference>
        <div
          class="select"
          :style="field.style_percent ? `width:${field.style_percent}%` : ``"
        >
          <div class="wrapper">
            <el-tag
              type="info"
              closable
              v-for="item in selection_nodes"
              :key="item"
              >{{ item.chs_name }}</el-tag
            >
          </div>
          <el-icon><ArrowDown /></el-icon>
        </div>
      </template>
      <!-- <el-table :data="user_list" style="height: 100%">
        <el-table-column label="姓名" width="400" @row-click="rowClick">
          <template #header>
            <el-input
              v-model="q"
              placeholder="手机号、昵称搜索"
              @input="inputChange"
            ></el-input>
          </template>
          <template #default="scope">
            <div class="flex-row item-center">
              <el-checkbox
                v-model="scope.row.isChecked"
                label=""
                size="large"
              />
              <el-avatar size="small" :src="scope.row.avatar_url" />
              <span style="margin-left: 10px"
                >{{ scope.row.name }} {{ scope.row.mobile_phone }}</span
              >
            </div>
          </template>
        </el-table-column>
      </el-table> -->
      <el-row :gutter="20" style="border: 1px solid var(--el-border-color)">
        <el-col :span="12">
          <div style="height: 35vh">
            <el-table
              ref="empTable"
              :data="user_list"
              row-key="id"
              @row-click="rowClick"
              style="height: 100%"
            >
              <el-table-column label="姓名" prop="name">
                <template #header>
                  <el-input
                    v-model="q"
                    placeholder="手机号、昵称搜索"
                    @input="inputChange"
                  ></el-input>
                </template>
                <template #default="scope">
                  <div class="flex-row item-center emp_item">
                    <el-checkbox
                      v-model="scope.row.isChecked"
                      label=""
                      size="large"
                    />
                    <el-avatar size="small" :src="scope.row.avatar_url" />
                    <div style="margin-left: 10px">
                      {{ scope.row.chs_name }} {{ scope.row.mobile_phone }}
                      <div
                        style="font-size: 12px; color: #999; line-height: 12px"
                      >
                        {{ scope.row.dept_name }}
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
        <el-col
          :span="12"
          style="border-left: 1px solid var(--el-border-color)"
        >
          <div style="height: 50px" class="flex-row item-center">
            已选择：{{ selection_nodes.length }}员工
          </div>
          <el-tag
            class="choose_member"
            v-for="(item, i) in selection_nodes"
            :key="item.id"
            closable
            size="large"
            type="info"
            style="margin-right: 20px; margin-bottom: 20px"
            @close="onRemove(i)"
          >
            <div class="flex-row item-center">
              <el-avatar :size="20" :src="item.avatar_url"></el-avatar>
              <span style="margin-left: 5px"
                >{{ item.chs_name }} {{ item.mobile_phone }}</span
              >
            </div>
          </el-tag>
        </el-col>
      </el-row>
    </el-popover>
    <div class="help-block" v-if="field.input_tips">{{ field.input_tips }}</div>
  </el-form-item>
</template>

<script>
export default {
  data() {
    return {
      q: "", //关键字
      user_list: [],
      //   options: [],
      //   values: [],
      //   props: {},
      selection_nodes: [],
      //   selection_list: [], //最终确认选中的人
    };
  },
  props: {
    /**
     * 表单字段
     */
    field: {
      type: Object,
      default: {},
    },
    value: {
      type: String,
      default: "",
    },
  },
  emits: ["change"],
  watch: {
    value: {
      handler() {
        this.getUser();
        if (this.value) {
          var list = this.value.split(",");
          list.forEach((id) => {
            var item = this.user_list.find((o) => o.id == id);
            if (item) {
              this.selection_nodes.push(item);
              item.isChecked = true;
            }
          });
          this.onChange();
        }
      },
      immediate: true,
    },
  },
  created() {
    // this.getUser();
    // var json = localStorage.getItem(this.$cfg.USER_SELECT_STORE_KEY);
    // console.log(json);
    // if (json) {
    //   this.user_list = JSON.parse(json);
    // }
  },
  methods: {
    /**
     *
     */
    getUser() {
      var json = localStorage.getItem(this.$cfg.USER_SELECT_STORE_KEY);
      if (json) {
        this.user_list = JSON.parse(json);
      }
    },

    // /**
    //  *
    //  */
    // loadData() {
    //   this.$http.get("admin/v1/user/select").then((res) => {
    //     if (res.code == 0 && res.data) {
    //       this.user_list = res.data;
    //       if (this.value) {
    //         var list = this.value.split(",");
    //         list.forEach((id) => {
    //           var item = this.user_list.find((o) => o.id == id);
    //           if (item) {
    //             this.selection_nodes.push(item);
    //             item.isChecked = true;
    //           }
    //         });
    //       }
    //     }
    //   });
    // },

    inputChange() {},
    /**
     * 点击选择
     * @param {*} node
     */
    rowClick(node) {
      if (!this.field.is_multiple) {
        this.selection_nodes = [];
        //
        this.user_list
          .filter((x) => x.isChecked && x.id != node.id)
          .forEach((m) => {
            m.isChecked = false;
          });
        if (node.isChecked) {
          node.isChecked = false;
        } else {
          node.isChecked = true;
          this.selection_nodes.push(node);
        }
      } else {
        if (node.isChecked) {
          node.isChecked = false;
          var i = this.selection_nodes.findIndex((o) => o.id == node.id);
          if (i > -1) {
            this.selection_nodes.splice(i, 1);
          }
        } else {
          node.isChecked = true;
          if (!this.selection_nodes.find((o) => o.id == node.id)) {
            this.selection_nodes.push(node);
          }
        }
      }
      this.onChange();
    },

    /**
     * 移除选择
     */
    onRemove(i) {
      var node = this.selection_nodes.splice(i, 1)[0];
      var item = this.user_list.find((o) => o.id == node.id);
      item.isChecked = false;
      this.onChange();
    },

    /**
     * 人员选择更改 返回，多选时id以逗号分割
     */
    onChange() {
      var userIds = [];
      //   if (this.field.is_multiple) {
      //     if (this.values.length) {
      //       userIds = this.values.map((x) => {
      //         return x[x.length - 1];
      //       });
      //     }
      //   } else {
      //     userIds = [this.values[this.values.length - 1]];
      //   }
      //   console.log();
      var userIds = this.selection_nodes.map((x) => x.id);
      this.$emit("change", userIds.join(","));
    },
  },
};
</script>
<style scoped>
.select {
  border-radius: var(--el-border-radius-base);
  box-shadow: 0 0 0 1px var(--el-border-color) inset;
  box-sizing: border-box;
  min-height: 34px;
  padding: 0px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  flex-grow: 1;
  width: 1;
  display: flex;
  flex-wrap: wrap;
}

.wrapper .el-tag {
  margin-right: 10px;
}

.emp_item {
  cursor: pointer;
}
</style>
<style>
.el-form-item.required .el-form-item__label {
  position: relative;
}
.el-form-item.required .el-form-item__label::before {
  content: "*";
  position: absolute;
  right: 5px;
  display: block;
  color: red;
}
</style>
