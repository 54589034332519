<!--
 * @Description: 附件上传
 * @Author: 琢磨先生
 * @Date: 2024-05-06 11:29:29
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-05-14 21:21:50
-->
<template>
  <el-form-item
    :label="field.name"
    :class="`${field.is_required ? 'required' : ''}`"
  >
    <el-upload
      v-model:file-list="fileList"
      class="upload_file"
      action=""
      multiple
      :style="field.style_percent ? `width:${field.style_percent}%` : ``"
      :limit="Number(field.max_num_restrict)"
      :on-remove="handleRemove"
      :before-upload="beforeUpload"
      :http-request="onUpload"
    >
      <el-button type="default">上传附件</el-button>
      <!-- <template #tip>
        <div class="el-upload__tip">
          jpg/png files with a size less than 500KB.
        </div>
      </template> -->
    </el-upload>
  </el-form-item>
</template>

<script>
import md5_util from "@/utils/md5_util.js";
import common_api from "@/http/common_api";
import axios from "axios";
export default {
  data() {
    return {
      fileList: [],
      batchId: "",
    };
  },
  props: {
    /**
     * 表单字段
     */
    field: {
      type: Object,
      default: {},
    },
    /**
     * 批次id
     */
    // batchId: {
    //   type: String,
    //   default: "",
    // },
    value: {
      type: String,
      default: "",
    },
  },
  emits: ["change"],
  watch: {
    value: {
      handler() {
        if (this.value) {
          var list = JSON.parse(this.value);
          if (list.length) {
            list.forEach((m) => {
              this.batchId = m.batch_id;
              this.fileList.push({
                name: m.name,
                url: m.file_url,
                status: "success",
              });
            });
            this.onChange();
          } else {
            this.getBatchId();
          }
        } else {
          this.getBatchId();
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    /**
     *
     */
    getBatchId() {
      common_api.get_batch_id().then((res) => {
        if (res.code == 0 && res.data) {
          this.batchId = res.data;
        }
      });
    },

    beforeUpload(file) {},
    /**
     * 移除
     */
    handleRemove(file) {
      if (file.file_id) {
        this.$http
          .get("seller/v1/crmFile/del?id=" + file.file_id)
          .then((res) => {
            if (res.code == 0) {
              this.onChange();
            }
          });
      }
    },

    /**
     *
     */
    onUpload(ctx) {
      this.uploadMfsHandler(ctx);
    },
    /**
     * 上传
     */
    uploadMfsHandler: async function (ctx) {
      var file = ctx.file;

      var self = this;
      //获取md5值
      var md5 = await md5_util.fileMd5(file);
      var ext =
        file.name.lastIndexOf(".") > -1
          ? file.name.substring(file.name.lastIndexOf("."))
          : "";
      var model = {
        size: file.size,
        psd_type: 3,
        name: file.name,
        is_new: true,
        ext: ext,
        md5: md5,
      };
      //
      var pre_file = this.fileList.find((o) => o.uid == file.uid);
      pre_file.status = "uploading";
      pre_file.ext = ext;

      var res = await this.$http.get("seller/v1/oss/file_md5?md5=" + md5);
      if (res.code == 0) {
        if (res.data) {
          this.saving = false;
          pre_file.percentage = 100.0;
          pre_file.url = res.data.file_url;
          pre_file.status = "success";
          this.onSaveFile(pre_file).then((res) => {
            if (res.code == 0) {
              pre_file.file_id = res.data.id;
              this.onChange();
            }
          });
          return;
        }
      }

      this.$http
        .post("seller/v1/oss/get_put_url", {
          bucket: "helpfile",
          ext: model.ext,
        })
        .then((res) => {
          if (res.code == 0 && res.data) {
            axios
              .put(res.data.upload_url, file, {
                headers: { "content-type": file.type },
                onUploadProgress(m) {
                  var num = (m.loaded * 100) / m.total;
                  pre_file.percentage = Math.round(num * 100) / 100;
                },
              })
              .then((response) => {
                if (response.status == 200) {
                  pre_file.status = "success";
                  pre_file.url = res.data.download_url;
                  self.onSaveFile(pre_file).then((res) => {
                    if (res.code == 0) {
                      pre_file.file_id = res.data.id;
                      self.onChange();
                    }
                  });
                  //存储资料库
                  model.file_url = res.data.download_url;
                  self.$http.post("seller/v1/oss/add", model);
                }
              });
          }
        })
        .finally(() => (this.saving = false));
    },
    /**
     * 更改
     */
    onChange() {
      // var values = this.fileList
      //   .filter((o) => o.status == "success")
      //   .map((m) => {
      //     return {
      //       name: m.name,
      //       size: m.size,
      //       url: m.url,
      //       ext: m.ext,
      //       file_id: m.file_id,
      //     };
      //   });
      // this.$emit("change", JSON.stringify(values));
      this.$emit("change", this.batchId);
    },

    /**
     * 保存Crm附件
     */
    onSaveFile(file) {
      return this.$http.post("seller/v1/crmFile/add", {
        name: file.name,
        size: file.size,
        file_url: file.url,
        file_type: 5,
        batch_id: this.batchId,
      });
    },
  },
};
</script>

<style>
.el-form-item.required .el-form-item__label {
  position: relative;
}
.el-form-item.required .el-form-item__label::before {
  content: "*";
  position: absolute;
  right: 5px;
  display: block;
  color: red;
}

.upload_file .el-upload-list__item {
  margin-bottom: 0;
}
</style>
