<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2024-04-29 18:13:54
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-05-10 08:02:22
-->
<template>
  <el-scrollbar>
    <el-menu
      :router="true"
        active-text-color="#ffd04b"
        background-color="#545c64"
      text-color="#fff"
      :default-active="meta.id"
    >
      <el-menu-item index="0" :route="{ path: '/dashboard' }">
        <el-icon> <house /> </el-icon>首页
      </el-menu-item>
      <sub-menu
        v-for="item in menu"
        :key="item"
        :father="item"
        :menus="item.children"
      ></sub-menu>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import SubMenu from "./submenu";

export default {
  name: "SideBar",
  components: {
    SubMenu,
  },
  data() {
    return {
      menu: [],
      meta: {},
    };
  },
  props: ["routes"],
  created() {
    var json = localStorage.getItem(this.$cfg.CURRENT_META_KEY);
    if (json) {
      this.meta = JSON.parse(json);
    } else {
      this.meta = {
        id: "0",
      };
    }
  },
  watch: {
    routes: {
      handler(arr) {
        this.menu = arr;
      },
      immediate: true,
    },
  },
};
</script>

<style>
/* .el-aside .el-sub-menu .el-menu::before {
  display: block;
  content: "";
  border-left: 1px dotted #11a1fd;
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  z-index: 1;
} */
/* .el-sub-menu.is-active > .el-sub-menu__title {
  background: #111;
} */
.el-sub-menu .el-menu li{
  overflow: hidden;
}

/* .el-sub-menu .el-menu li::before{
  position: absolute;
  border-left: 1px dotted #11a1fd;
  display: block;
  content: "";
  left: 20px;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.el-sub-menu .el-menu li:last-child::before{
  bottom: 25px;
}

.el-aside .el-sub-menu .el-menu li::after {
  display: block;
  content: "";
  border-top: 1px dotted #11a1fd;
  position: absolute;
  left: 20px;
  z-index: 1;
  width: 7px;
}

.el-aside .el-sub-menu li.el-menu-item.is-active::after {
  display: block;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 5px solid;
  border-color: transparent transparent transparent #11a1fd;
} */
</style>
