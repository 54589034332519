<template>
  <div>{{ text }}</div>
</template>

<script>
export default {
  data() {
    return {
      text: "",
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
            this.text= val;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
 
</style>
