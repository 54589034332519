<!--
 * @Description: 单行文本
 * @Author: 琢磨先生
 * @Date: 2024-05-06 09:30:41
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-05-22 10:33:00
-->
<template>
  <el-form-item
    :label="field.name"
    :class="`${field.is_required ? 'required' : ''}`"
  >
    <el-upload
      ref="upload"
      class="uploader"
      multiple
      v-model:file-list="fileList"
      action=""
      list-type="picture-card"
      accept=".jpg,.jpeg,.png,.gif"
      :limit="Number(field.max_num_restrict)"
      :http-request="onUpload"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :before-upload="onBeforeUpload"
    >
      <el-icon><Plus /></el-icon>
      <template #tip>
        <div class="el-upload__tip">
          支持gif，jpg，png等格式图片，最多可添加{{ field.max_num_restrict }}张
        </div>
      </template>
    </el-upload>

    <div class="help-block" v-if="field.input_tips">{{ field.input_tips }}</div>
  </el-form-item>
</template>

<script>
import md5_util from "@/utils/md5_util.js";
import common_api from "@/http/common_api";
import axios from "axios";
export default {
  data() {
    return {
      fileList: [],
      srcList: [], //已上传的地址
      batchId: "",
    };
  },
  props: {
    /**
     * 表单字段
     */
    field: {
      type: Object,
      default: {},
    },
    // /**
    //  * 批次id
    //  */
    // batchId: {
    //   type: String,
    //   default: "",
    // },
    value: {
      type: String,
      default: "",
    },
  },
  emits: ["change"],
  watch: {
    value: {
      handler() {
        if (this.value) { 
          var list = JSON.parse(this.value);
          if (list.length) {
            list.forEach((m) => {
              this.batchId = m.batch_id;
              this.fileList.push({
                url: m.file_url,
                status: "success",
                file_id:m.id,
              });
              this.srcList.push(m);
            });
            this.onChange();
          } else {
            this.getBatchId();
          }
        } else {
          this.getBatchId();
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    /**
     * 获取批次id
     */
    getBatchId() {
      common_api.get_batch_id().then((res) => {
        if (res.code == 0 && res.data) {
          this.batchId = res.data;
        }
      });
    },
    /**
     * 预览
     */
    handlePictureCardPreview() {},

    /**
     * 移除
     */
    handleRemove(file) {
      if (file.status == "success") {
        var i = this.srcList.findIndex((o) => o == file.url);
        if (i > -1) {
          this.srcList.splice(i, 1);
          // this.onChange();
        }
      }
      if (file.file_id) {
        this.$http
          .get("seller/v1/crmFile/del?id=" + file.file_id)
          .then((res) => {
            if (res.code == 0) {
              this.onChange();
            }
          });
      }
    },

    /**
     * 上传前获取图片宽高
     */
    onBeforeUpload(file) {
      return new Promise((resolve) => {
        let img = new Image();
        img.onload = function () {
          file.width = img.width;
          file.height = img.height;
          resolve(file);
        };
        img.src = URL.createObjectURL(file);
      });
    },

    /**
     * 上传提交
     */
    onUpload(ctx) {
      this.uploadMfsHandler(ctx, ctx.file);
    },

    /**
     * 上传
     */
    uploadMfsHandler: async function (ctx, file) {
      //   ctx.onProgress({ percent: 0 });

      var self = this;
      //获取md5值
      var md5 = await md5_util.fileMd5(file);
      //获取图片长宽
      await this.handlerWH(file);

      var model = {
        height: file.height,
        width: file.width,
        size: file.size,
        psd_type: 1,
        name: file.name,
        is_new: true,
        ext: `.${file.type.replace("image/", "")}`,
        md5: md5,
      };

      //
      var pre_file = this.fileList.find((o) => o.uid == file.uid);
      pre_file.status = "uploading";

      var res = await this.$http.get("seller/v1/oss/file_md5?md5=" + md5);
      if (res.code == 0) {
        if (res.data) {
          this.saving = false;
          // this.percentage = 100.0;
          pre_file.percentage = 100.0;
          pre_file.url = res.data.file_url;
          pre_file.status = "success";
          this.srcList.push(res.data.file_url);
          //   this.$emit("change", this.srcList, this.ak);
          // this.onChange();
          this.onSaveFile(pre_file).then((res) => {
            if (res.code == 0) {
              pre_file.file_id = res.data.id;
              this.onChange();
            }
          });
          return;
        }
      }

      this.$http
        .post("seller/v1/oss/get_put_url", {
          bucket: "helpimg",
          ext: model.ext,
        })
        .then((res) => {
          if (res.code == 0 && res.data) {
            axios
              .put(res.data.upload_url, file, {
                headers: { "content-type": file.type },
                onUploadProgress(m) {
                  // self.percentage = m.progress * 100;
                  //   pre_file.percentage = m.progress * 100;
                  var num = (m.loaded * 100) / m.total;
                  pre_file.percentage = Math.round(num * 100) / 100;
                },
              })
              .then((response) => {
                if (response.status == 200) {
                  pre_file.status = "success";
                  pre_file.url = res.data.download_url;

                  this.srcList.push(res.data.download_url);
                  //   self.$emit("change", this.srcList);
                  // self.onChange();
                  self.onSaveFile(pre_file).then((res) => {
                    if (res.code == 0) {
                      pre_file.file_id = res.data.id;
                      self.onChange();
                    }
                  });
                  model.file_url = res.data.download_url;
                  self.$http.post("seller/v1/oss/add", model);
                }
              });
          }
        })
        .finally(() => (this.saving = false));
    },

    /**
     * 处理获取图片的宽高
     */
    handlerWH(file) {
      return new Promise((resolve) => {
        let img = new Image();
        img.onload = function () {
          file.width = img.width;
          file.height = img.height;
          resolve(file);
        };
        img.src = URL.createObjectURL(file);
      });
    },

    /**
     * 上传成功
     */
    onChange() {
      this.$emit("change", this.batchId);
    },

    /**
     * 保存Crm附件
     */
    onSaveFile(file) {
      return this.$http.post("seller/v1/crmFile/add", {
        name: file.name,
        size: file.size,
        file_url: file.url,
        file_type: 0,
        batch_id: this.batchId,
      });
    },
  },
};
</script>

<style>
.el-form-item.required .el-form-item__label {
  position: relative;
}
.el-form-item.required .el-form-item__label::before {
  content: "*";
  position: absolute;
  right: 5px;
  display: block;
  color: red;
}

.uploader .el-upload--picture-card {
  --el-upload-picture-card-size: 100px;
}
.uploader .el-upload-list--picture-card {
  --el-upload-list-picture-card-size: 100px;
}
</style>
