<template>
  <field-table-image
    v-if="field.type == 'image'"
    :value="value"
  ></field-table-image>
  <field-table-region
    v-else-if="field.type == 'region'"
    :value="value"
  ></field-table-region>
  <field-table-tag
    v-else-if="field.type == 'cust_tag'"
    :value="value"
  ></field-table-tag>
  <field-table-file
    v-else-if="field.type == 'file'"
    :value="value"
  ></field-table-file>
  <field-table-date
    v-else-if="field.type == 'date'"
    :value="value"
  ></field-table-date>
  <field-table-datetime
    v-else-if="field.type == 'datetime'"
    :value="value"
  ></field-table-datetime>
  <div v-else>
  {{ textValue }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      textValue: "",
    };
  },
  props: {
    field: {
      type: Object,
      default: {},
    },

    value: {
      type: [String, Object],
      default: "",
    },
    link: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val && Array.isArray(val)) {
          this.textValue = val.join(",");
        } else {
          this.textValue = val;
        }
      },
    },
  },
};
</script>

<style scoped></style>
