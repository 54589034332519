<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2024-05-08 16:37:56
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-05-09 00:11:13
-->

<template>
  <field-preview-image
    v-if="field.type == 'image'"
    :value="value"
  ></field-preview-image>
  <field-preview-region
    v-else-if="field.type == 'region'"
    :value="value"
  ></field-preview-region>
  <field-preview-checkbox
    v-else-if="field.type == 'checkbox'"
    :value="value"
  ></field-preview-checkbox>
  <field-preview-file
    v-else-if="field.type == 'file'"
    :value="value"
  ></field-preview-file>
  <field-preview-emp
    v-else-if="field.type == 'emp'"
    :value="value"
  ></field-preview-emp>
  <!-- <field-preview-tag
    v-else-if="field.type == 'cust_tag'"
    :value="value"
  ></field-preview-tag> -->
  <div v-else>
    {{ value }}
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      default: {},
    },

    value: {
      type: String,
      default: "",
    },
    link: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
