<template>
  <el-popover :width="800" trigger="click">
    <template #reference>
      <div class="select">
        <div class="wrapper">
          <el-tag
            type="info"
            closable
            v-for="item in selection_nodes"
            :key="item"
            >{{ item.chs_name }}</el-tag
          >
        </div>
        <el-icon><ArrowDown /></el-icon>
      </div>
    </template>
    <el-row :gutter="20" style="border: 1px solid var(--el-border-color)">
      <el-col :span="12">
        <div style="height: 35vh">
          <el-table
            ref="empTable"
            :data="user_list"
            row-key="id"
            @row-click="rowClick"
            style="height: 100%"
          >
            <el-table-column label="姓名" prop="name">
              <template #header>
                <el-input
                  v-model="q"
                  placeholder="手机号、昵称搜索"
                  @input="inputChange"
                ></el-input>
              </template>
              <template #default="scope">
                <div class="flex-row item-center emp_item">
                  <el-checkbox
                    v-model="scope.row.isChecked"
                    label=""
                    size="large"
                  />
                  <el-avatar size="small" :src="scope.row.avatar_url" />
                  <div style="margin-left: 10px">
                    {{ scope.row.chs_name }} {{ scope.row.mobile_phone }}
                    <div
                      style="font-size: 12px; color: #999; line-height: 12px"
                    >
                      {{ scope.row.dept_name }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
      <el-col :span="12" style="border-left: 1px solid var(--el-border-color)">
        <div style="height: 50px" class="flex-row item-center">
          已选择：{{ selection_nodes.length }}员工
        </div>
        <el-tag
          class="choose_member"
          v-for="(item, i) in selection_nodes"
          :key="item.id"
          closable
          size="large"
          type="info"
          style="margin-right: 20px; margin-bottom: 20px"
          @close="onRemove(i)"
        >
          <div class="flex-row item-center">
            <el-avatar :size="20" :src="item.avatar_url"></el-avatar>
            <span style="margin-left: 5px"
              >{{ item.chs_name }} {{ item.mobile_phone }}</span
            >
          </div>
        </el-tag>
      </el-col>
    </el-row>
  </el-popover>
</template>

<script>
export default {
  data() {
    return {
      q: "", //关键字
      user_list: [],
      selection_nodes: [],
    };
  },
  props: {
    /**
     * 多选
     */
    is_multiple: {
      type: Boolean,
      default: false,
    },
    /**
     * 默认值
     */
    value: {
      type: String,
      default: "",
    },
  },
  emits: ["change"],
  watch: {
    value: {
      handler() {
        if (this.value) {
          if (this.value) {
            var list = this.value.split(",");
            list.forEach((id) => {
              var item = this.user_list.find((o) => o.id == id);
              if (item) {
                this.selection_nodes.push(item);
                item.isChecked = true;
              }
            });
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    var json = localStorage.getItem(this.$cfg.USER_SELECT_STORE_KEY);
    if (json) {
      this.user_list = JSON.parse(json);
    }
  },
  methods: {
    // /**
    //  *
    //  */
    // loadData() {
    //   this.$http.get("admin/v1/user/select").then((res) => {
    //     if (res.code == 0 && res.data) {
    //       this.user_list = res.data;
    //       if (this.value) {
    //         var list = this.value.split(",");
    //         list.forEach((id) => {
    //           var item = this.user_list.find((o) => o.id == id);
    //           if (item) {
    //             this.selection_nodes.push(item);
    //             item.isChecked = true;
    //           }
    //         });
    //       }
    //     }
    //   });
    // },

    inputChange() {},
    /**
     * 点击选择
     * @param {*} node
     */
    rowClick(node) {
      if (!this.is_multiple) {
        this.selection_nodes = [];
        //
        this.user_list
          .filter((x) => x.isChecked && x.id != node.id)
          .forEach((m) => {
            m.isChecked = false;
          });
        if (node.isChecked) {
          node.isChecked = false;
        } else {
          node.isChecked = true;
          this.selection_nodes.push(node);
        }
      } else {
        if (node.isChecked) {
          node.isChecked = false;
          var i = this.selection_nodes.findIndex((o) => o.id == node.id);
          if (i > -1) {
            this.selection_nodes.splice(i, 1);
          }
        } else {
          node.isChecked = true;
          if (!this.selection_nodes.find((o) => o.id == node.id)) {
            this.selection_nodes.push(node);
          }
        }
      }
      this.onChange();
    },

    /**
     * 移除选择
     */
    onRemove(i) {
      var node = this.selection_nodes.splice(i, 1)[0];
      var item = this.user_list.find((o) => o.id == node.id);
      item.isChecked = false;
      this.onChange();
    },

    /**
     * 人员选择更改 返回，多选时id以逗号分割
     */
    onChange() {
      var userIds = [];
      var userIds = this.selection_nodes.map((x) => x.id);
      this.$emit("change", userIds.join(","));
    },
  },
};
</script>
<style scoped>
.select {
  border-radius: var(--el-border-radius-base);
  box-shadow: 0 0 0 1px var(--el-border-color) inset;
  box-sizing: border-box;
  min-height: 34px;
  padding: 0px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.wrapper {
  flex-grow: 1;
  width: 1;
  display: flex;
  flex-wrap: wrap;
}

.wrapper .el-tag {
  margin-right: 10px;
}

.emp_item {
  cursor: pointer;
}
</style>
<style>
.el-form-item.required .el-form-item__label {
  position: relative;
}
.el-form-item.required .el-form-item__label::before {
  content: "*";
  position: absolute;
  right: 5px;
  display: block;
  color: red;
}
</style>
