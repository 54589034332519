<template>
  <div class="files">
    <div class="item" v-for="item in srcList" :key="item">
      {{ item.name
      }}<span class="size">({{ (item.size / 1024).toFixed(2) }}KB)</span>
      <el-link
        :underline="false"
        size="small"
        type="primary"
        @click="onDownload(item)"
        style="font-size:12px;"
        target="_blank"
        >下载</el-link
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      srcList: [],
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  watch: {
    value: {
      handler() {
        this.srcList=[];
        if (this.value) {
          this.srcList = JSON.parse(this.value);
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 下载
     */
    onDownload(item) {
      axios({
        url: item.url,
        method: "GET",
        responseType: "blob",
      }).then((res) => {
        const blob = new Blob([res.data]);
        var url = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = item.name;
        a.click();
        URL.revokeObjectURL(url);
      });
    },
  },
};
</script>

<style scoped>
.files {
  display: flex;
  flex-wrap: wrap;
}
.files .item {
  padding-bottom: 2px 0;
}
.files .item:last-child {
  padding-bottom: 0;
}
.size {
  color: #999;
}
</style>
